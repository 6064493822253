import { MaybeComputedRefOrFalsy } from '@unhead/vue';

type Type = 'article' | 'website' | 'book' | 'profile';

const MAX_TITLE_LENGTH = 60;
const MAX_DESCRIPTION_LENGTH = 160;

export default function ({
  title,
  description,
  image,
  type,
  slice
}: {
  title: MaybeComputedRefOrFalsy<string>;
  description: MaybeComputedRefOrFalsy<string>;
  image: MaybeComputedRefOrFalsy<string>;
  type?: MaybeComputedRefOrFalsy<Type>;
  slice?: boolean;
}) {
  const {
    public: { cloudflareImageProcessing: cfUrl }
  } = useRuntimeConfig();
  const { t } = useI18n();
  const imageHost = useHost(true);

  const titleRef = computed<string>(() => {
    if (typeof title === 'string') {
      return title;
    }
    if (typeof title === 'function') {
      return title();
    }
    return title;
  });

  const descriptionRef = computed<string>(() => {
    if (typeof description === 'string') {
      return description;
    }
    if (typeof description === 'function') {
      return description();
    }
    return description;
  });

  const imageRef = computed<string>(() => {
    if (typeof image === 'string') {
      return image;
    }
    if (typeof image === 'function') {
      return image();
    }
    return image;
  });

  const typeRef = computed<Type>(() => {
    if (typeof type === 'string') {
      return type;
    }
    if (typeof type === 'function') {
      return type();
    }
    return (
      type && typeof type === 'object' && 'value' in type
        ? type?.value || 'website'
        : 'website'
    ) as Type;
  });

  const twitterCard = computed(() =>
    typeRef.value === 'article' ? 'summary_large_image' : 'summary'
  );

  function fixImageUrl(value: string) {
    if (value.startsWith('/') && !value.includes(imageHost)) {
      value = `${imageHost}${value}`;
    }
    if (value && !value.startsWith('/') && !value.includes(cfUrl)) {
      return `${cfUrl}/image?w=1200&h=630&f=webp&fit=crop&image=${value}`;
    }
    return value;
  }

  const processedImage = computed(() =>
    imageRef.value ? fixImageUrl(imageRef.value) : imageRef.value
  );

  const processedTitle = computed(() => {
    let str = titleRef.value;
    if (str && slice) {
      // Check for different separators
      const separator = str.includes(' | ')
        ? ' | '
        : str.includes(' - ')
        ? ' - '
        : '';

      if (separator) {
        const splitTitle = str.split(separator);
        const mainPart = splitTitle
          .slice(0, splitTitle.length - 1)
          .join(separator);
        const suffix = splitTitle[splitTitle.length - 1];

        const maxTitleLength =
          MAX_TITLE_LENGTH - suffix.length - separator.length;

        if (mainPart.length > maxTitleLength) {
          str =
            mainPart.slice(0, maxTitleLength - 4).trim() +
            '...' +
            separator +
            suffix;
        } else {
          str = mainPart + separator + suffix;
        }
      } else if (str.length > MAX_TITLE_LENGTH) {
        str = str.slice(0, MAX_TITLE_LENGTH - 4).trim() + '...';
      }
    }
    return str;
  });

  const processedDescription = computed(() => {
    let str = descriptionRef.value;
    if (str && slice) {
      if (str.length > MAX_DESCRIPTION_LENGTH) {
        str = str.slice(0, MAX_DESCRIPTION_LENGTH - 4).trim() + '...';
      }
    }
    return str;
  });

  useSeoMeta({
    ...(title
      ? {
          title: processedTitle,
          ogTitle: title,
          twitterTitle: title
        }
      : {}),
    ...(description
      ? {
          description: processedDescription,
          ogDescription: description,
          twitterDescription: description
        }
      : {}),
    ...(image
      ? {
          ogImage: processedImage,
          twitterImage: processedImage
        }
      : {}),
    ogType: typeRef,
    twitterCard,
    ogSiteName: t('seo.defaultTitle')
  });
}
